import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from 'reactstrap'
import { getCurrentLangKey, getUrlForLang } from 'ptz-i18n'
import I18n from './i18n'
import logo from '../images/austriaguides.png'

const isPartiallyActive = ({ isPartiallyCurrent }) => isPartiallyCurrent ? { className: "nav-link px-0 px-lg-4 active" } : null;

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  getNavUrlForLanguage(currentLang, currentUrl, lang) {
    if (currentLang === 'de') {
      return `/${lang}${currentUrl}`;
    } else if (lang === 'de') {
      return this.props.location.origin + currentUrl.replace(`/${currentLang}`, '');
    }

    return getUrlForLang(`/${currentLang}/`, currentUrl, lang)
  }

  render() {
    const langs = ['de', 'en', 'it']
    const defaultLangKey = 'de'
    const langKey = getCurrentLangKey(langs, defaultLangKey, this.props.location.pathname)

    const i18nNav = {
      home: {
        link: langKey === 'de' ? '/' : `/${langKey}`,
      },
      tours: {
        link: langKey === 'de' ? '/tours/' : `/${langKey}/tours/`,
      },
      contact: {
        link: langKey === 'de' ? '/contact/' : `/${langKey}/contact/`,
      }
    }

    return (
      <header>
        <Navbar color="faded" light expand="lg" className="shadow">
          <div className="container">
            <NavbarBrand to={i18nNav.home.link} tag={Link} className="text-center">
              <img src={logo} width="135" height="55" alt={this.props.siteTitle} />
            </NavbarBrand>

            <NavbarToggler onClick={this.toggle} className="border-0" />

            <Collapse isOpen={this.state.isOpen} navbar>
              <ul className="navbar-nav mx-auto">
                <li className="nav-item">
                  <Link className="nav-link px-0 px-lg-4" to={i18nNav.home.link} activeClassName="active"><I18n value="header.home" /></Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link px-0 px-lg-4" to={i18nNav.tours.link} activeClassName="active" getProps={isPartiallyActive}><I18n value="header.tours" /></Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link px-0 px-lg-4" to={i18nNav.contact.link} activeClassName="active"><I18n value="header.contact" /></Link>
                </li>
              </ul>

              <Nav navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle nav caret className="text-uppercase">{langKey}</DropdownToggle>
                  <DropdownMenu right>
                    {langs.filter(l => l !== langKey).map(lang => <DropdownItem href={this.getNavUrlForLanguage(langKey, this.props.location.pathname, lang)} className="text-uppercase" key={lang}>{lang}</DropdownItem>)}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </div>
        </Navbar>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired
}

export default Header