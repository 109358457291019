import React from 'react'
import PropTypes from 'prop-types'
import { getCurrentLangKey } from 'ptz-i18n'
import { Location } from '@reach/router'

export default class I18n extends React.PureComponent {

  getActiveLangKey(location) {
    const langs = ['de', 'en', 'it', 'fr']
    const defaultLangKey = 'de'
    return getCurrentLangKey(langs, defaultLangKey, location.pathname)
  }

  render() {
    const translations = {
      'de': {
        'header.home': 'Home',
        'header.tours': 'Touren & Preise',
        'header.contact': 'Kontakt',
        'footer.tos': 'AGB',
        'footer.imprint': 'Impressum',
        'footer.data-privacy': 'Datenschutz',
        'modal.submit': 'Absenden',
        'modal.loading': 'Wir geladen...',
        'modal.success': 'Vielen Dank für Ihre Anfrage, wir werden Sie in Kürze kontaktieren!',
        'modal.error': 'Ihre Anfrage konnte leider nicht bearbeitet werden. Bitte probieren Sie es etwas später erneut.',
        'modal.mandatory': 'mit * gekennzeichnete Felder bitte ausfüllen',
        'modal.left.text': 'Wir beraten Sie gerne individuell und kostenlos.',
        'modal.right.text': 'Oder kontaktieren Sie uns gleich telefonisch. Wir freuen uns auf Ihre Anfrage!',
        'tours.heading': 'Ihre Fremdenführer',
        'tours.prices': 'Richtpreise',
        'tours.offer': 'Unser Angebot',
        'tours.offer.city': 'Ortsführungen',
        'tours.offer.city.time': 'bis zu 1,5 Std',
        'tours.offer.halfday': 'Halbtagesausflüge',
        'tours.offer.halfday.time': 'bis zu 4 Std',
        'tours.offer.fullday': 'Ganztagesausflüge',
        'tours.offer.fullday.time': 'bis zu 8 Std',
        'tours.offer.themenfuehrungen': 'Themenführungen',
        'tours.offer.additional': 'jede zusätzliche Stunde',
        'tours.offer.foreignfee': 'Preise verstehen sich exkl. MWSt.',
        'tours.offer.surcharge': '20 % Zuschlag für Fremdsprache',
        'tours.offer.calltoaction': 'Jetzt Angebot anfordern',
        'tours.offer.groupsize': 'Gruppengröße max. 25 Personen',
        'tours.offer.pricing.start': 'ab',
      },
      'en': {
        'header.home': 'Home',
        'header.tours': 'Tours & Pricing',
        'header.contact': 'Contact',
        'footer.tos': 'TOS',
        'footer.imprint': 'Imprint',
        'footer.data-privacy': 'Data Privacy',
        'modal.submit': 'Send',
        'modal.loading': 'Loading...',
        'modal.success': 'Thanks for your request, we will contact you soon!',
        'modal.error': 'Sorry, your request could not be processed, please try again a little later.',
        'modal.mandatory': '* mandatory fields',
        'modal.left.text': 'We are happy to create your individual offer for free.',
        'modal.right.text': 'Or contact us directly by phone. We are looking forward for your request.',
        'tours.heading': 'Your Local Guides',
        'tours.prices': 'Prices',
        'tours.offer': 'Our Offers',
        'tours.offer.city': 'City/town tour',
        'tours.offer.city.time': 'up to 1,5 hours',
        'tours.offer.halfday': 'Half-day excursion',
        'tours.offer.halfday.time': 'up to 4 hours',
        'tours.offer.fullday': 'Full-day excursion',
        'tours.offer.fullday.time': 'up to 8 hours',
        'tours.offer.additional': 'Each additional hour',
        'tours.offer.themenfuehrungen': 'Themed guided tour',
        'tours.offer.foreignfee': 'All prices excl. VAT',
        'tours.offer.surcharge': '20% surcharge for foreign language',
        'tours.offer.calltoaction': 'Request a quote now',
        'tours.offer.groupsize': 'Group size max. 25 people',
        'tours.offer.pricing.start': 'starting at',
      },
      'it': {
        'header.home': 'Home',
        'header.tours': 'Itinerari e Tariffe',
        'header.contact': 'Contatti',
        'footer.tos': 'TOS',
        'footer.imprint': 'Impressum',
        'footer.data-privacy': 'Datenschutz',
        'modal.submit': 'Absenden',
        'modal.loading': 'Wir geladen...',
        'modal.success': 'Vielen Dank für Ihre Anfrage, wir werden Sie in Kürze kontaktieren!',
        'modal.error': 'Ihre Anfrage konnte leider nicht bearbeitet werden. Bitte probieren Sie es etwas später erneut.',
        'modal.mandatory': 'mit * gekennzeichnete Felder bitte ausfüllen',
        'modal.left.text': 'Wir beraten Sie gerne individuell und kostenlos.',
        'modal.right.text': 'Oder kontaktieren Sie uns gleich telefonisch. Wir freuen uns auf Ihre Anfrage!',
        'tours.heading': 'Le vostre guide',
        'tours.prices': 'Prezzi Indicativi',
        'tours.offer': 'La Nostra Offerta',
        'tours.offer.city': 'Visite guidate di città e paesi fino',
        'tours.offer.city.time': 'ad 1,5 ora',
        'tours.offer.halfday': 'Gite di mezza giornata fino',
        'tours.offer.halfday.time': 'a 4 ore',
        'tours.offer.fullday': 'Gite di un´intera giornata fino',
        'tours.offer.fullday.time': 'a 8 ore',
        'tours.offer.themenfuehrungen': 'Visite tematiche',
        'tours.offer.additional': 'Ogni ora aggiuntiva',
        'tours.offer.foreignfee': 'Prezzi esenti da IVA',
        'tours.offer.surcharge': 'Supplemento del 20% per la lingua straniera',
        'tours.offer.calltoaction': 'Richiedete un preventivo gratuito',
        'tours.offer.groupsize': 'Max. 25 persone per gruppo',
        'tours.offer.pricing.start': 'a partire da',
      },
      'fr': {},
    }

    return (
      <Location>
        {({ location }) => {
          return translations[this.getActiveLangKey(location)][this.props.value] || '';
        }}
      </Location>
    )
  }
}

I18n.propTypes = {
  value: PropTypes.string.isRequired
}
