import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql, Link } from 'gatsby'
import CookieConsent from "react-cookie-consent"
import { getCurrentLangKey } from 'ptz-i18n'
import Header from './header'
import Footer from './footer'
import '../scss/austriaguides.scss'

const langs = ['de', 'en', 'fr', 'it'];
const defaultLangKey = 'de';

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            googleSiteVerification
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Austria Guides - Salzkammergut' },
            { name: 'keywords', content: 'Salzkammergut, Hallstatt, Austria Guides' },
            { name: 'google-site-verification', content: data.site.siteMetadata.googleSiteVerification },
          ]}
        >
        <html lang={getCurrentLangKey(langs, defaultLangKey, location.pathname)} />
        </Helmet>
        <div className="p-lg-3 px-lg-5" style={{backgroundColor: '#f0f0f0'}}>
          <div className="bg-white mx-lg-5">
            <Header siteTitle={data.site.siteMetadata.title} location={location} />
            {children}
            <Footer location={location} />
          </div>
        </div>
        <CookieConsent
            location="bottom"
            buttonText="Akzeptieren"
            style={{ color: '#333333', background: "#f0f0f0", fontSize: "13px" }}
            buttonStyle={{ background: '#d80d16', color: "#ffffff", fontSize: "13px" }}
            expires={150}
        >
            Wir verwenden Cookies um die Webseite möglichst benutzerfreundlich zu gestalten. Durch die Nutzung unserer Webseite erklären Sie sich damit einverstanden, dass wir Cookies setzen. <Link to={`datenschutz/`}>Mehr erfahren.</Link>
        </CookieConsent>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired
}

export default Layout
