import React from 'react'
import { Link } from 'gatsby'
import { Container, Row } from 'reactstrap'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import I18n from './i18n'

const footer = () => (
  <div>
    <div className="w-100">
      <svg viewBox="0 -80 1500 200" transform="scale(-1,1)" style={{ marginBottom: '-10px' }}>
        <g>
          <path d="m-9.5,118.74482l507.5,-3.13793l304,-66.52413l36,22.5931l101,-32.67586l76,51l76,-38.44828l71,32.63449l101,22.5931l239,53.22069l4,60.99999l-1515,0l-0.5,-92.25517z" fill="#f0f0f0" />
        </g>
      </svg>
    </div>
    <footer className="py-5">
      <Container>
        <Row>
          <div className="col-12 col-md-6 sls mt-5 text-center text-md-left">
            Website &amp; Design by <OutboundLink href="https://schmidleithner.at">schmidleithner.at</OutboundLink>
          </div>
          <div className="col-12 col-md-6 mt-5">
            <ul className="nav justify-content-center justify-content-md-end">
              <li className="nav-item">
                <Link to={'/tos/'} activeClassName="active"><I18n value="footer.tos" /></Link>
              </li>
              <li className="nav-item">
                <Link to={'/datenschutz/'} activeClassName="active"><I18n value="footer.data-privacy" /></Link>
              </li>
              <li className="nav-item">
                <Link to={'/impressum/'} activeClassName="active"><I18n value="footer.imprint" /></Link>
              </li>
            </ul>
          </div>
        </Row>
      </Container>
    </footer>
  </div>
)

export default footer